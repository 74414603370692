<template>
  <div class="page-wrapper">
    <div class="container">
      <div class="page-inner">
        <Multiselect
          v-model="screenType"
          :options="screenTypes"
          track-by="id"
          label="name"
          class="customer-type-change"
          :show-labels="false"
          :allow-empty="false"
          :searchable="false"
        >
        </Multiselect>
        <div v-if="!mobile || (mobile && screenType.id === 1)" class="customer__wrapper">
          <div class="customer__title">
            <div key="2" class="customer__title-text">{{ customer.businessName }}</div>
          </div>
          <div class="customer__main">
            <div class="customer__main-col">
              <div class="customer__main-title">Basic Data</div>
              <div class="customer__main-row customer__main-row--half">
                <div class="customer__main-cell">
                  <div class="cell__title">First Name</div>
                  <div class="cell__text">{{ customer.firstName }}</div>
                </div>
                <div v-if="customer.isPublic" class="customer__main-cell">
                  <div class="cell__title">Middle Initial</div>
                  <div class="cell__text">{{ customer.middleInitial }}</div>
                </div>
                <div class="customer__main-cell">
                  <div class="cell__title">Last Name</div>
                  <div class="cell__text">{{ customer.firstName }}</div>
                </div>
                <div class="customer__main-cell">
                  <div class="cell__title">Nickname</div>
                  <div class="cell__text">{{ customer.nickName }}</div>
                </div>
                  <div v-if="customer.isPublic" class="customer__main-cell">
                  <div class="cell__title">Father’s Name</div>
                  <div class="cell__text">{{ customer.fathersName }}</div>
                </div>
                <div class="customer__main-cell">
                  <div class="cell__title">Main Cell Phone</div>
                  <div class="cell__text">{{ customer.cellPhone }}</div>
                </div>
              </div>
              <div class="customer__main-row customer__main-row--half">
                <div class="customer__main-cell">
                  <div class="cell__title">Contact E-mail</div>
                  <div class="cell__text">{{ customer.contactEmail }}</div>
                </div>
              </div>
              </div>
              <div class="customer__main-row customer__main-row--half">
                <!-- <div class="customer__main-cell">
                  <div class="cell__title">Last Name</div>
                  <div class="cell__text">{{ customer.firstName }}</div>
                </div>
                <div class="customer__main-cell">
                  <div class="cell__title">Nickname</div>
                  <div class="cell__text">{{ customer.nickName }}</div>
                </div> -->
              </div>
              <div class="customer__main-row customer__main-row--half">
                <!-- <div v-if="customer.isPublic" class="customer__main-cell">
                  <div class="cell__title">Father’s Name</div>
                  <div class="cell__text">{{ customer.fathersName }}</div>
                </div>
                <div class="customer__main-cell">
                  <div class="cell__title">Main Cell Phone</div>
                  <div class="cell__text">{{ customer.cellPhone }}</div>
                </div>
              </div>
              <div class="customer__main-row customer__main-row--half">
                <div class="customer__main-cell">
                  <div class="cell__title">Contact E-mail</div>
                  <div class="cell__text">{{ customer.contactEmail }}</div>
                </div>
              </div> -->
            </div>
            <div class="customer__main-col">
              <div class="customer__main-title">Business Information</div>
              <div class="customer__main-row customer__main-row--half">
                <div class="customer__main-cell">
                  <div class="cell__title">Business Name</div>
                  <div v-if="customer.businessName" class="cell__text">{{ customer.businessName }}</div>
                  <div v-else class="cell__text">—</div>
                </div>
                <div class="customer__main-cell">
                  <div class="cell__title">Quick Books Name</div>
                  <div v-if="customer.quickBooksName" class="cell__text">{{ customer.quickBooksName }}</div>
                  <div v-else class="cell__text">—</div>
                </div>
              </div>
              <div class="customer__main-row customer__main-row--half">
                <div class="customer__main-cell">
                  <div class="cell__title">Business Phone</div>
                  <div v-if="customer.businessPhone" class="cell__text">{{ customer.businessPhone }}</div>
                  <div v-else class="cell__text">—</div>
                </div>
                <div class="customer__main-cell">
                  <div class="cell__title">Accounting E-mail</div>
                  <div v-if="customer.accountingEmail" class="cell__text">{{ customer.accountingEmail }}</div>
                  <div v-else class="cell__text">—</div>
                </div>
              </div>
            </div>
            <div class="customer__main-col">
              <div class="customer__main-title">Address Information</div>
              <div class="customer__main-row customer__main-row--half">
                <div class="customer__main-cell">
                  <div class="cell__title">Address</div>
                  <div v-if="customer.address" class="cell__text">{{ customer.address }}</div>
                  <div v-else class="cell__text">—</div>
                </div>
                <div class="customer__main-cell">
                  <div class="cell__title">City</div>
                  <div v-if="customer.city" class="cell__text">{{ customer.city }}</div>
                  <div v-else class="cell__text">—</div>
                </div>
              </div>
              <div class="customer__main-row customer__main-row--half">
                <div class="customer__main-cell">
                  <div class="cell__title">Province</div>
                  <div v-if="customer.province" class="cell__text">{{ customer.province }}</div>
                  <div v-else class="cell__text">—</div>
                </div>
                <div class="customer__main-cell">
                  <div class="cell__title">Postal Code</div>
                  <div v-if="customer.postalCode" class="cell__text">{{ customer.postalCode }}</div>
                  <div v-else class="cell__text">—</div>
                </div>
              </div>
            </div>
            <div class="customer__main-col">
              <div class="customer__main-title">Personal Information</div>
              <div class="customer__main-row customer__main-row--half">
                <div class="customer__main-cell">
                  <div class="cell__title">Home Phone</div>
                  <div v-if="customer.homePhone" class="cell__text">{{ customer.homePhone }}</div>
                  <div v-else class="cell__text">—</div>
                </div>
              </div>
              <div class="customer__main-row">
                <div class="cell__title">Notes</div>
                <div v-if="customer.homePhone" class="cell__text">{{ customer.notes }}</div>
                <div v-else class="cell__text">—</div>
              </div>
              <div class="customer__main-row">
                <el-image
                  v-if="customer.attachmentPath"
                  :src="customer.attachmentPath"
                  :preview-src-list="[customer.attachmentPath]"
                  fit="cover"
                  class="customer__image"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="!mobile || (mobile && screenType.id === 2)" class="deliveries__wrapper">
          <div class="deliveries__title">
            <Skeleton v-if="isLoading" key="1" :height="'30px'" />
            <div v-else key="2" class="deliveries__title-text">Deliveries by {{ customer.businessName }}</div>
          </div>
          <Deliveries />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '@/components/Skeleton'
import Deliveries from './Deliveries'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Customer',
  components: {Skeleton, Deliveries, Multiselect},
  data: () => ({
    isLoading: true,
    screenType: {name: 'Customer Info', id: 1},
    screenTypes: [
      {name: 'Customer Info', id: 1},
      {name: 'Deliveries', id: 2},
      {name: 'Orders', id: 3}
    ],
    mobile: false
  }),
  computed: {
    customer() {
      return this.$store.getters['customers/customer']
    }
  },
  async created() {
    this.mobile = document.documentElement.clientWidth <= 480
    const id = this.$route.params.id
    this.$store.dispatch('customers/fetchCustomer', id)
    this.isLoading = false

  },
  beforeDestroy() {
    this.$store.commit('customers/setCustomer', {})
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
