<template>
  <div class="deliveries__main">
    <Skeleton v-if="isLoading" key="1" :height="'53px'" />
    <div v-else class="deliveries__main-inner">
      <div v-for="delivery of deliveries" :key="delivery.id" class="deliveries__row">
        <div class="deliveries__cell deliveries__cell-date">{{ moment(delivery.routeDate).format('DD MMM YYYY') }}</div>
        <div class="deliveries__cell deliveries__cell-id">Delivery # {{ delivery.id }}</div>
        <div v-if="!delivery.status" class="deliveries__cell deliveries__cell-status">
          <div class="indicator indicator--opacity indicator--red">Not Done</div>
        </div>
        <div v-else-if="delivery.status === 1" class="deliveries__cell">
          <div class="indicator indicator--opacity indicator--orange">Picked Up</div>
        </div>
        <div v-else-if="delivery.status === 2" class="deliveries__cell">
          <div class="indicator indicator--opacity indicator--blue">Done</div>
        </div>

        <div class="deliveries__actions" @click.stop>
          <el-dropdown trigger="click" @command="action">
            <i class="ri-more-fill"></i>
            <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
              <!-- <el-dropdown-item class="item--edit" :command="{type: 'Edit', ...delivery}">
                <i class="ri-pencil-fill"></i> Edit
              </el-dropdown-item> -->
              <el-dropdown-item class="item--remove" :command="{type: 'Archive', ...delivery}">
                <i class="ri-delete-bin-7-fill"></i> Archive
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoadingDeliveries && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import Skeleton from '@/components/Skeleton'
import {mapState, mapActions, mapMutations} from 'vuex'
import moment from 'moment'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'DeliveriesCustomer',
  components: {Skeleton, InfiniteLoading},
  data: () => ({
    customerID: null,
    isLoading: true,
    isLoadingDeliveries: false,
    moment
  }),
  computed: {
    ...mapState({
      deliveries: s => s.customers.deliveries.deliveries,
      totalCount: s => s.customers.deliveries.pagination.total,
      page: s => s.customers.deliveries.page
    }),
    hasMoreResults() {
      return this.deliveries.length < this.totalCount && this.page > 1
    }
  },
  async created() {
    const id = this.$route.params.id
    this.customerID = id
    await this.fetch(id)
    this.isLoading = false
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapActions({
      fetch: 'customers/deliveries/fetch',
      archive: 'deliveries/archive'
    }),
    ...mapMutations({
      reset: 'customers/deliveries/reset'
    }),
    async action(act) {
      if (act.type === 'Edit') {
        // this.$root.$emit('ShowSidebar', {
        //   sidebarName: 'AddDelivery',
        //   componentData: act
        // })
      } else if (act.type === 'Archive') {
        this.isLoading = true
        await this.archive(act.id)
        this.reset()
        await this.fetch(this.customerID)
        this.isLoading = false
      }
    },
    async infLoad() {
      this.isLoadingDeliveries = true
      try {
        await this.fetch(this.customerID)
        this.$emit('loaded')
      } finally {
        this.isLoadingDeliveries = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
