import { render, staticRenderFns } from "./index.vue?vue&type=template&id=657c18fb&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=657c18fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657c18fb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/sts-app_develop/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('657c18fb')) {
      api.createRecord('657c18fb', component.options)
    } else {
      api.reload('657c18fb', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=657c18fb&scoped=true&", function () {
      api.rerender('657c18fb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Admin/Customer/index.vue"
export default component.exports