var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "deliveries__main" },
    [
      _vm.isLoading
        ? _c("Skeleton", { key: "1", attrs: { height: "53px" } })
        : _c(
            "div",
            { staticClass: "deliveries__main-inner" },
            _vm._l(_vm.deliveries, function(delivery) {
              return _c(
                "div",
                { key: delivery.id, staticClass: "deliveries__row" },
                [
                  _c(
                    "div",
                    { staticClass: "deliveries__cell deliveries__cell-date" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.moment(delivery.routeDate).format("DD MMM YYYY")
                        )
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "deliveries__cell deliveries__cell-id" },
                    [_vm._v("Delivery # " + _vm._s(delivery.id))]
                  ),
                  !delivery.status
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "deliveries__cell deliveries__cell-status"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "indicator indicator--opacity indicator--red"
                            },
                            [_vm._v("Not Done")]
                          )
                        ]
                      )
                    : delivery.status === 1
                    ? _c("div", { staticClass: "deliveries__cell" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "indicator indicator--opacity indicator--orange"
                          },
                          [_vm._v("Picked Up")]
                        )
                      ])
                    : delivery.status === 2
                    ? _c("div", { staticClass: "deliveries__cell" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "indicator indicator--opacity indicator--blue"
                          },
                          [_vm._v("Done")]
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "deliveries__actions",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    },
                    [
                      _c(
                        "el-dropdown",
                        {
                          attrs: { trigger: "click" },
                          on: { command: _vm.action }
                        },
                        [
                          _c("i", { staticClass: "ri-more-fill" }),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass:
                                "el-popper-custom el-popper--actions",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown"
                            },
                            [
                              _c(
                                "el-dropdown-item",
                                {
                                  staticClass: "item--remove",
                                  attrs: {
                                    command: Object.assign(
                                      {},
                                      { type: "Archive" },
                                      delivery
                                    )
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "ri-delete-bin-7-fill"
                                  }),
                                  _vm._v(" Archive ")
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            0
          ),
      !_vm.isLoadingDeliveries && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.infLoad } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }